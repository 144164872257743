*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
	font-family: "Inter", Arial, Helvetica, sans-serif;
  line-height: 1.4;
  letter-spacing: 0.5px;
  font-size: 12px; 
  padding: 2.5em;
  background: #FFFCF5;
  color: #191919;
}

@media (max-width: 30em) { 
  body{
    padding: 1em;
  }
}  

@font-face {
  font-family: Inter;
  src: url(./assets/Inter-VariableFont_slnt,wght.ttf) format('truetype-variations');
}

h1 {
	font-size: 40px;
	letter-spacing: 0.5px;
	line-height: 1;
}

h2 {
	font-size: 32px;
}

h3 {
	font-size: 20px;
}

h4 {
	font-size: 16px;
}

ul {
  list-style-type: none;
}

a  {
  color: rgb(0,0,0);
  text-decoration: none;
}

img{
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


@media (max-width: 30em) { 
  img{
    width: 100%;
  } 
}  


picture img, video {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
