@import url('https://fonts.googleapis.com/css?family=Fira+Mono:400');

.error-article{ 
    margin: auto;
    display: flex;
    min-height: calc(100vh - 50px - 33.8px - 5em);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: calc(5em);
    padding-bottom: calc(5em);
}

.error-article .error-title{ 
    font-family: 'Fira Mono', monospace; 
    font-size: 2rem;  
    margin: 0; 
} 

@media (min-width: 48em) {
    .error-article .error-title{
        font-size: 2.5rem;  
    }
}

@media (min-width: 64em) {
    .error-article .error-title{
        font-size: 3rem; 
        font-weight: 700;
    }
}  

.error-text{
    margin-bottom: 2rem;
    text-align: center;
}

.error-button{
    color: inherit;
    text-decoration: underline;
} 

@media (max-width: 30em) {  
    .error-article .error-title{ 
        font-size: 20px;  
    }
}  
