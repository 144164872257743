
.loading-indicator {
    font-weight: 500;
    left: 50%;
    margin-bottom: 0;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%,-50%,0);
    transition: opacity 500ms ease;
}

.ready .loading-indicator {
    opacity: 0
}

.loading-indicator__item {
    animation-delay: var(--duration-medium);
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    opacity: 0
}

.loading-indicator__item--1 {
    animation-name: item-1
}

.loading-indicator__item--2 {
    animation-name: item-2
}

.loading-indicator__item--3 {
    animation-name: item-3
}

.loading-indicator__item--4 {
    animation-name: item-4
}

.loading-indicator__item--5 {
    animation-name: item-5
}

.loading-indicator__item--6 {
    animation-name: item-6
}

.loading-indicator__item--7 {
    animation-name: item-7
}

@keyframes item-1 {
    0% {
        opacity: 0
    }

    10% {
        opacity: 1
    }

    20% {
        opacity: 1
    }

    30% {
        opacity: 1
    }

    40% {
        opacity: 0
    }

    50% {
        opacity: 0
    }

    60% {
        opacity: 0
    }

    70% {
        opacity: 0
    }

    80% {
        opacity: 0
    }

    90% {
        opacity: 0
    }

    to {
        opacity: 0
    }
}

@keyframes item-2 {
    0% {
        opacity: 0
    }

    10% {
        opacity: 0
    }

    20% {
        opacity: 1
    }

    30% {
        opacity: 1
    }

    40% {
        opacity: 1
    }

    50% {
        opacity: 0
    }

    60% {
        opacity: 0
    }

    70% {
        opacity: 0
    }

    80% {
        opacity: 0
    }

    90% {
        opacity: 0
    }

    to {
        opacity: 0
    }
}

@keyframes item-3 {
    0% {
        opacity: 0
    }

    10% {
        opacity: 0
    }

    20% {
        opacity: 0
    }

    30% {
        opacity: 1
    }

    40% {
        opacity: 1
    }

    50% {
        opacity: 1
    }

    60% {
        opacity: 0
    }

    70% {
        opacity: 0
    }

    80% {
        opacity: 0
    }

    90% {
        opacity: 0
    }

    to {
        opacity: 0
    }
}

@keyframes item-4 {
    0% {
        opacity: 0
    }

    10% {
        opacity: 0
    }

    20% {
        opacity: 0
    }

    30% {
        opacity: 0
    }

    40% {
        opacity: 1
    }

    50% {
        opacity: 1
    }

    60% {
        opacity: 1
    }

    70% {
        opacity: 0
    }

    80% {
        opacity: 0
    }

    90% {
        opacity: 0
    }

    to {
        opacity: 0
    }
}

@keyframes item-5 {
    0% {
        opacity: 0
    }

    10% {
        opacity: 0
    }

    20% {
        opacity: 0
    }

    30% {
        opacity: 0
    }

    40% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    60% {
        opacity: 1
    }

    70% {
        opacity: 1
    }

    80% {
        opacity: 0
    }

    90% {
        opacity: 0
    }

    to {
        opacity: 0
    }
}

@keyframes item-6 {
    0% {
        opacity: 0
    }

    10% {
        opacity: 0
    }

    20% {
        opacity: 0
    }

    30% {
        opacity: 0
    }

    40% {
        opacity: 0
    }

    50% {
        opacity: 0
    }

    60% {
        opacity: 1
    }

    70% {
        opacity: 1
    }

    80% {
        opacity: 1
    }

    90% {
        opacity: 0
    }

    to {
        opacity: 0
    }
}

@keyframes item-7 {
    0% {
        opacity: 0
    }

    10% {
        opacity: 0
    }

    20% {
        opacity: 0
    }

    30% {
        opacity: 0
    }

    40% {
        opacity: 0
    }

    50% {
        opacity: 0
    }

    60% {
        opacity: 0
    }

    70% {
        opacity: 1
    }

    80% {
        opacity: 1
    }

    90% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}
