.home-article{ 
    margin: auto; 
    min-height: calc(100vh - 50px - 33.8px - 5em);
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    padding-top: calc(5em);
    padding-bottom: calc(5em);
}

.home-wrapper {
    padding: 1em;
    display: grid;
    grid-template-columns: auto 1fr;
    margin: auto;
}

.home-wrapper-section {
    padding: 1em; 
}

@media (max-width: 1024px){
    .home-wrapper { 
        grid-template-columns: 1fr; 
    }
}

.home-wrapper-content{
    padding-bottom: 10px;
}

.home-description {
    font-size: 40px; 
}

@media (max-width: 30em) { 
    .home-description {
        font-size: 20px; 
    }
    .home-wrapper {
        padding: 0em;
        padding-left: 1em;
        padding-right: 1em;
    }
}  


.home-title a:hover, .home-description a:hover {
    text-decoration: line-through;
}

.home-wrapper-links-container {
    display: flex;
    align-items: center;
    width: 163.77px;
    /* padding-top: 5em; */
}

.home-wrapper-links-container-2 {
    display: flex;
    align-items: center; 
}

.links-container-line{
    flex: 1; 
    background-color: #000;
    height: 1px;
    margin: 0px 10px;
} 

.links-container-link-idel, .links-container-link-hover{
    opacity : 1;
    transition: all .5s ease;
}

.links-container-link-not-hover{
    opacity : 0.6;
    transition: all .5s ease;
}